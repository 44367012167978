import { getContrastColor } from '@/lib/color'
import { CapacitorService } from '@/lib/services/Capacitor'
import { clone }            from '@/lib/utils/helper'

const LOGO_TYPE = {
  ICONS: 'icons',
  GMAP : 'gmap',
  LAZY : 'lazy',
  MAIN : 'main'
}
const LOGO_COLOR = {
  LIGHT: 'light',
  DARK : 'dark'
}

export default {
  data () {
    return {
      LOGO_TYPE : LOGO_TYPE,
      LOGO_COLOR: LOGO_COLOR
    }
  },
  methods: {
    getMenuItemTags (menuItem) {
      const AppTags = this.appConfig?.TAGS || []
      const tags = []

      menuItem.Tags.forEach(tagId => {
        const tag = AppTags.find(appTag => appTag.Id === tagId)
        if (tag && tag.Active) tags.push(clone(tag))
      })

      return tags
    },
    getMeasurementUnitById (id) {
      if (!id) return null

      return this.$t('Account.UserOrders.Unit.Units')?.find(item => item.Id === id) || null // this.appConfig?.MEASUREMENT_UNITS?.find(item => item.Id === id) || null
    },
    getContrastLogo (type, color) {
      const logoContrast = color === LOGO_COLOR.LIGHT || color === LOGO_COLOR.DARK ? color : !color ? LOGO_COLOR.LIGHT : getContrastColor(color) === 'white' ? LOGO_COLOR.LIGHT : LOGO_COLOR.DARK
      if (type === LOGO_TYPE.ICONS) return this.getIcons(logoContrast)
      if (type === LOGO_TYPE.GMAP) return this.getGMapIcon(logoContrast)
      if (type === LOGO_TYPE.LAZY) return this.getLazyLogo(logoContrast)
      if (type === LOGO_TYPE.MAIN) return this.getLogo(logoContrast)

      return this.getLogo(logoContrast)
    },
    getGMapIcon (type = LOGO_COLOR.LIGHT) {
      return type === LOGO_COLOR.DARK ? this.appConfig.LOCATION_DATA.GMapIconDark : this.appConfig.LOCATION_DATA.GMapIcon
    },
    getLazyLogo (type = LOGO_COLOR.LIGHT) {
      return type === LOGO_COLOR.DARK ? this.appConfig.LOCATION_DATA.LazyLogoDark : this.appConfig.LOCATION_DATA.LazyLogo
    },
    getLogo (type = LOGO_COLOR.LIGHT) {
      return type === LOGO_COLOR.DARK ? this.appConfig.LOCATION_DATA.LogoDark : this.appConfig.LOCATION_DATA.Logo
    },
    getIcons (type = LOGO_COLOR.LIGHT) {
      return type === LOGO_COLOR.DARK ? this.appConfig.LOCATION_DATA.IconsDark : this.appConfig.LOCATION_DATA.Icons
    },

    goToStore (url) {
      if (!url) return
      if (CapacitorService.isNative) {
        const isSplashScreenAvailable = CapacitorService.isPluginAvailable('SplashScreen')
        if (isSplashScreenAvailable) CapacitorService.Plugins.SplashScreen.show()
      }
      window && window.location.assign(`//${ url }?t=lp`)
    }
  },
  computed: {
    showDeliveryAreas () {
      return this?.appConfig?.LOCATION_DATA?.HasDeliveryAreas && this?.appConfig?.LOCATION_DATA?.ShowDeliveryAreas || false
    },

    showDeliveryAreasCharges () {
      return this.showDeliveryAreas && this?.appConfig?.LOCATION_DATA?.ShowDeliveryAreasCharges || false
    },

    hasMultipleLocations () {
      return this.multipleLocations.length > 1
    },
    multipleLocations () {
      return this.appConfig?.LOCATION_DATA?.MultipleLocations ?? []
    },
    hasLandingPage () {
      return this.appSettings?.HasLandingPage && this.landingPage
    },
    landingPage () {
      return this.appSettings?.LandingPageUrl ?? ''
    },
    isCatalogOnly () {
      return this.appSettings?.CatalogOnly ?? false
    },

    appSettings () {
      return this.appConfig.APP_SETTINGS
    },
    appConfig: {
      get () {
        return window.AppConfig
      },
      set (val) {
        window.AppConfig = val
      }
    }
  }
}
