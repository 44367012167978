<template>
  <v-list-tile
    v-if="menu"
    :disabled="isMenuItemDisabled(menu)"
    :inactive="!isUserEnabled || isMenuItemDisabled(menu)"
    avatar
    class="gfp-adv-pages-catalog-sections-catalog-product-background menu-item"
    ripple
    @click="onFeaturedMenuClick(menu)"
  >
    <div
      v-if="isMenuItemInCart(menu)"
      style="width: 3px; height: 100%; position: absolute; margin-left: -8px;"
      class="success"
    />

    <div
      v-if="menu.Image && leftImage"
      :style="$vuetify.breakpoint.smAndDown ? 'height: 90px; width: 125px;' : 'height: 90px; width: 150px;'"
      class="mr-2"
    >
      <img
        v-lazy="menu.Image"
        style="border-radius: 6px; object-fit: cover;"
        :style="{
          height: '90px',
          width: $vuetify.breakpoint.smAndDown ? '125px' : '150px',
          filter: (isMenuItemDisabled(menu) ? 'grayscale(1)' : 'grayscale(0)')
        }"
      >
    </div>

    <v-list-tile-content class="justify-start align-start pr-1">
      <v-list-tile-title :style="{'padding-right': leftImage && quickAddTop ? '32px' : 0}">
        <span
          v-if="menu.Number"
          class="gfp-adv-pages-catalog-sections-catalog-product-number-background pa-1 mr-2 gfp-adv-pages-catalog-sections-catalog-product-number-text--text text-xs-center"
        >
          {{ menu.Number }}
        </span>

        <span
          v-if="menuItemQuantityInCart(menu)"
          class="font-weight-bold gfp-adv-pages-catalog-sections-catalog-product-quantity--text"
          style="font-size: 16px;"
        >
          <template v-if="menu.DetailsData.UnitId > 1">
            {{ formatUnitQuantity($i18n.locale, menuItemQuantityInCart(menu), getMeasurementUnitById(menu.DetailsData.UnitId)) }}
          </template>
          <template v-else>
            {{ menuItemQuantityInCart(menu) }} x
          </template>
        </span>

        <span
          class="font-weight-medium gfp-adv-pages-catalog-sections-catalog-product-title--text"
          :style="{'font-size': ($vuetify.breakpoint.xsOnly ? '13px' : '14px')}"
        >
          {{ menu.Lang[$i18n.locale].Name }}
        </span>
      </v-list-tile-title>

      <v-list-tile-sub-title
        v-if="menu.Lang[$i18n.locale].Description"
        style="flex: 0 0 auto"
        :style="{'padding-right': leftImage && quickAddTop ? '32px' : 0}"
        class="gfp-adv-pages-catalog-sections-catalog-product-description--text"
        v-html="menu.Lang[$i18n.locale].Description"
      />

      <div style="font-size: 14px; flex: 1 1 auto; display: flex; flex-flow: column nowrap; justify-content: flex-end; width: 100%;">
        <div>
          <template v-if="menu.SpecialStatus && menu.IsSpecial">
            <div
              :class="[isMenuItemDisabled(menu) ? 'grey--text lighten-1' : 'gfp-adv-pages-catalog-sections-catalog-product-price--text']"
              class="pt-1 white--text"
            >
              <span class="old-price grey--text text--darken-4">
                {{ menu.ShowCalculatedPrice ? (parseFloat(menu.OptionsPrice) + parseFloat(menu.Price)) : menu.Price | currency }}
              </span> {{ menu.ShowCalculatedPrice ? (parseFloat(menu.OptionsPrice) + parseFloat(menu.SpecialPrice)) : menu.SpecialPrice | currency }}
            </div>
          </template>
          <template v-else>
            <div
              :class="[isMenuItemDisabled(menu) ? 'grey--text lighten-1' : 'gfp-adv-pages-catalog-sections-catalog-product-price--text']"
              class="pt-1 white--text"
            >
              <template v-if="menu.DetailsData.UnitId > 1">
                {{ getMenuUnitBasePriceWithOptions(menu) | currency }}
                <span
                  class="grey--text text-caption"
                >/ {{ getMeasurementUnitById(menu.DetailsData.UnitId).BaseUnit }}</span>
              </template>
              <template v-else>
                {{ menu.ShowCalculatedPrice ? (parseFloat(menu.OptionsPrice) + parseFloat(menu.Price)) : menu.Price | currency }}
              </template>
            </div>
          </template>
        </div>
      </div>
    </v-list-tile-content>

    <div
      v-if="menu.Image && !leftImage"
      :style="$vuetify.breakpoint.smAndDown ? 'height: 90px; width: 125px;' : 'height: 90px; width: 150px;'"
    >
      <img
        v-lazy="menu.Image"
        style="border-radius: 6px; object-fit: cover;"
        :style="{
          height: '90px',
          width: $vuetify.breakpoint.smAndDown ? '125px' : '150px',
          filter: (isMenuItemDisabled(menu) ? 'grayscale(1)' : 'grayscale(0)')
        }"
      >
    </div>

    <template v-if="isQuickAddMenuItemEnabled">
      <div :style="quickAddPositionStyle">
        <v-btn
          :disabled="isMenuItemDisabled(menu)"
          :class="[isMenuItemDisabled(menu) ? 'grey lighten-1' : 'gfp-adv-pages-catalog-sections-catalog-product-quick-add-button-background']"
          class="gfp-adv-pages-catalog-sections-catalog-product-quick-add-button-text--text pa-0"
          :style="quickAddButtonStyle"
          flat
          small
          ripple
          @click.native.stop="onQuickAddMenuClick(menu)"
        >
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="16"
            width="2"
            color="gfp-adv-pages-catalog-sections-catalog-product-quick-add-button-text"
            style="margin: 3px;"
          />
          <v-icon v-else>
            add
          </v-icon>
        </v-btn>
      </div>
    </template>

    <div :style="(leftImage ? isQuickAddMenuItemEnabled ? 'margin-right: 40px;' : 'margin-right: 5px;' : !menu.Image ? isQuickAddMenuItemEnabled ? 'margin-right: 40px;' : 'margin-right: 5px;' : tagsMarginStyle) + tagsPositionStyle ">
      <template v-for="tag in getMenuItemTags(menu)">
        <v-tooltip
          v-if="tag.Visible && !tag.System"
          :key="`menu-tag-tooltip-${tag.Id}`"
          :color="tag.Color || '#666666'"
          bottom
        >
          <template #activator="{on}">
            <v-chip
              v-if="tag.Visible && !tag.System"
              :key="`menu-tag-${tag.Id}`"
              :color="tag.Color || '#666666'"
              small
              label
              class="menu-tag"
              :style="`color: ${getContrastColor(tag.Color || '#666666', true, 135)}`"
              v-on="on"
            >
              <v-icon
                v-if="tag.Icon && !tag.LabelOnly"
                small
              >
                {{ tag.Icon }}
              </v-icon>

              <span
                v-if="!tag.Icon || tag.LabelOnly"
                class="caption"
                :class="{'ml-1': !!tag.Icon && !tag.LabelOnly}"
              >
                {{ tag.Label }}
              </span>
            </v-chip>
          </template>

          <div :style="`color: ${getContrastColor(tag.Color || '#666666', true, 135)}`">
            <div class="font-weight-bold text-xs-center">
              {{ tag.Lang[$i18n.locale].Label }}
            </div>
            <div class="caption text-xs-left">
              {{ tag.Lang[$i18n.locale].Description }}
            </div>
          </div>
        </v-tooltip>
      </template>
    </div>
  </v-list-tile>
</template>

<script>

import AppData                from '@/mixins/appdata'
import MenuBlockMixin         from '@/components/menu/MenuBlockMixin'
import { getContrastColor }   from '@/lib/color'
import { formatUnitQuantity } from '@/lib/currency/currency'

export default {
  name      : 'ForgotToAddCartMenuItem',
  components: {},
  directives: {},
  mixins    : [AppData, MenuBlockMixin],
  props     : {
    menu: {
      type   : Object,
      default: undefined
    }
  },
  data () {
    return {
      loading    : false,
      quickAddTop: false
    }
  },
  computed: {
    leftImage () {
      return this?.appConfig?.LOCATION_DATA?.MenuListItemImageLeft || false
    },

    tagsPositionStyle () {
      return 'position: absolute; bottom: 1px; right: -2px;'
    },
    tagsMarginStyle () {
      return this.$vuetify.breakpoint.smAndDown ? 'margin-right: 135px;' : 'margin-right: 160px;'
    },

    quickAddPositionStyle () {
      if (!this.quickAddTop) return 'position: absolute; bottom: 7px; right: 6px;'

      return 'position: absolute; top: 1px; right: -2px;'
    },
    quickAddButtonStyle () {
      if (!this.quickAddTop) return 'min-width: 30px; height: 30px; border-radius: 16px 0px 4px 0px;'

      return 'min-width: 30px; height: 30px; border-radius: 0px 4px 0px 16px;'
    }
  },
  watch: {},
  beforeCreate () {},
  created () {
    this.$bus.$on('save:menuOptionsItem', this.saveMenuOptions)
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off('save:menuOptionsItem', this.saveMenuOptions)
  },
  destroyed () {},
  methods: {
    formatUnitQuantity,
    getContrastColor,

    getMenuCategoryById (categoryId) {
      const items = this.dataStoreMenu?.items || []
      const category = items.find(item => this.menu.CategoryId === categoryId)?.Category

      return category
    },

    saveMenuOptions (menu) {
      if (menu.Id === this.menu.Id) {
        this.loading = true
        this.$bus.$once('cart:add:response', (menu) => {
          if (menu?.Id === this.menu?.Id) {
            this.loading = false
          }
        })
      }
    },

    onFeaturedMenuClick (menu) {
      if (!menu || this.loading) return

      this.$bus.$emit('show-menu-options-dialog', {
        menuItem: menu,
        editMode: false
      })
    },

    onQuickAddMenuClick (menu) {
      const menuCategory = this.getMenuCategoryById(menu?.CategoryId)
      if (!menu || !menuCategory || this.loading) return

      if (this.isQuickAddMenuItemEnabled) {
        this.loading = true
        this.onQuickAddMenuItem(menu, menuCategory)
        this.$bus.$once('cart:add:response', (menu) => {
          if (menu?.Id === this.menu?.Id) {
            this.loading = false
          }
        })
      }
    }
  }
}
</script>

<style scoped>
/deep/ .price-tag {
  border-radius : 2px;
  min-width     : 55px;
  min-height    : 28px;
  text-align    : center;
}

/deep/ .v-list--three-line .v-list__tile {
  height : 104px !important;
}

/deep/ .v-list--three-line .v-list__tile__sub-title {
  white-space        : normal;
  -webkit-line-clamp : 2;
  -webkit-box-orient : vertical;
  display            : -webkit-box;
}

/deep/ .menu-item a.v-list__tile,
/deep/ .menu-item div.v-list__tile {
  padding : 6px;
}

.menu-tag {
  margin        : 0;
  margin-right  : 4px;
  margin-bottom : 6px;
  height        : 18px !important;
  border-radius : 3px;
}

/deep/ .menu-tag .v-chip__content {
  padding : 2px;
}
</style>
