import AppData                from '@/mixins/appdata'
import i18nRouteMeta          from '@/mixins/i18nRouteMeta'
import MenuItemCommon         from '@/mixins/menu/menuItemCommon'
import DataEventEnum          from '@/lib/services/enum/DataEventEnum'
import DataEventService       from '@/lib/services/event/DataEventService'
import greekUtils             from '../common/greek-utils/index'
import { formatUnitQuantity } from '@/lib/currency/currency'

export default {
  components: {},
  directives: {},
  mixins    : [AppData, MenuItemCommon, i18nRouteMeta],
  props     : {
    slug: {
      type    : String,
      required: false
    },
    allCategories: {
      type   : Boolean,
      default: true
    },
    hasFeaturedMenus: {
      type   : Boolean,
      default: false
    }
  },
  data () {
    return {
      title                : this.$t('Menu.Title'),
      desc                 : '',
      img                  : '',
      search               : '',
      searchProduct        : '',
      debounceSearchTimeout: null,
      activeCategoryId     : -1,
      selectedMenuItem     : null,
      posMenuItemsActive   : [],
      posMenuHasItems      : false,
      previousScroll       : 0,
      offsetTop            : 0,
      windowSize           : {
        x: 0,
        y: 0
      },
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween : 12,
        loop         : true,
        autoplay     : {
          delay               : 2500,
          disableOnInteraction: true
        }
      }
    }
  },
  computed: {
    isQuickAddMenuItemEnabled () {
      return (this?.appConfig?.LOCATION_DATA?.QuickAddMenuItem) && !this.isCatalogOnly && !this.dataStore?.cart?.IsClosed && !this.dataStore?.cart?.Messages?.OrderType
    },

    isUserEnabled () {
      if (this.$auth.isAuthenticated()) {
        if (!this.dataStore.userInfo.Info.Status) return false
      }
      return true
    },

    UseMenuLandingPage () {
      return this.appConfig.LOCATION_DATA && this.appConfig.LOCATION_DATA.hasOwnProperty('UseMenuLandingPage') ? this.appConfig.LOCATION_DATA.UseMenuLandingPage : false
    }
  },
  watch: {
    allCategories () {
      this.getCategoryDetails(this.getCategoryBySlug(this.slug), true)
    },
    posMenuItemsActive: {
      handler: function () {
        let count = 0
        for (const item in this.posMenuItemsActive) {
          // eslint-disable-next-line
          for (let menu in this.posMenuItemsActive[item].Items) {
            if (menu.Lang && menu.Lang[this.$i18n.locale]) count++
          }
        }
        this.posMenuHasItems = count > 0
      },
      deep: true
    }
  },
  beforeCreate () {},
  created () {
    this.$bus.$on('menu-category-selected', this.getCategoryDetails)
    this.$bus.$on('menu-product-search', this.onSearchProduct)

    if (this.dataStoreMenu) {
      const category = this.dataStoreMenu.categories.find(category => (category.Lang[this.$i18n.locale] && category.Lang[this.$i18n.locale].Slug === this.slug))
      this.getCategoryDetails(category)
    }
  },
  beforeMount () {},
  mounted () {},
  beforeUpdate () {},
  updated () {},
  beforeDestroy () {
    this.$bus.$off('menu-category-selected', this.getCategoryDetails)
    this.$bus.$off('menu-product-search', this.onSearchProduct)
  },
  destroyed () {},
  methods: {
    onQuickAddMenuItem (menu, category) {
      if (this.isMenuItemDisabled(menu) || menu.QuickAddLoading) return

      this.selectedMenuItem = menu.DetailsData

      this.initMenuItem()

      if (!this.isRequiredOptionsValid()) {
        this.showAddMenuOptionsDialog(menu, category)
        return
      }

      this.$set(menu, 'QuickAddLoading', true)

      this.$bus.$emit('save:menuOptionsItem', this.selectedMenuItem)

      this.$bus.$once('cart:add:response', () => {
        this.$set(menu, 'QuickAddLoading', false)
      })
    },

    visibilityChanged (isVisible, entry, category) {
      if (isVisible) {
        category.ObserveVisibility = isVisible
      }
    },

    isMenuItemDisabled (menu) {
      return (menu.Status ? (menu.SubtractStock && menu.Quantity <= 0) : !menu.Status)
    },

    menuItemQuantityInCart (menu) {
      let quantity = 0
      this.dataStore.cart.CategoryItems.forEach(category => {
        category.Items.forEach(item => {
          if (parseInt(item.Id) === parseInt(menu.Id)) quantity = quantity + parseInt(item.UnitId > 1 ? item.UnitQuantity : item.Quantity)
        })
      })
      return quantity
    },

    isMenuItemInCart (menu) {
      return !!this.dataStore.cart.CategoryItems.find(category => !!category.Items.find(item => parseInt(item.Id) === parseInt(menu.Id)))
    },

    showAddMenuOptionsDialog (menuItem, category) {
      if (this.isMenuItemDisabled(menuItem) || menuItem.QuickAddLoading) return
      if (!this.isUserEnabled || (menuItem?.Status ? (menuItem.SubtractStock && menuItem.Quantity <= 0) : !menuItem.Status)) return
      const menuItemQuantity = parseInt(menuItem.UnitId > 1 ? menuItem.UnitQuantity : menuItem.Quantity)
      const menuItemStockQuantity = parseInt(menuItem.Quantity)

      let isStockValid = true
      if (menuItem.SubtractStock) {
        let totalStockUsed = 0

        this.dataStore.cart.CategoryItems.forEach(category => {
          category.Items.forEach(product => {
            if (parseInt(menuItem.Id) === parseInt(product.Id)) {
              totalStockUsed += parseInt(menuItem.UnitId > 1 ? product.UnitQuantity : product.Quantity)
            }
          })
        })

        isStockValid = totalStockUsed < menuItemQuantity
      }

      if (!isStockValid) {
        this.$bus.$emit('show-snackbar', {
          title: this.$t('Cart.Error.Stock.Invalid.Title'),
          body : this.$t('Cart.Error.Stock.Invalid.Body', { menuItem: menuItem.Name }),
          type : 'warning',
          icon : ''
        })
        return
      }

      if ((menuItemStockQuantity < menuItem.MinimumQuantity) && menuItem.SubtractStock) {
        this.$bus.$emit('show-snackbar', {
          title: this.$t('Cart.Error.Stock.MinimumQuantity.Title'),
          body : this.$t('Cart.Error.Stock.MinimumQuantity.Body', {
            menuItem       : menuItem.Name,
            minimumQuantity: formatUnitQuantity(this.$i18n.locale, menuItem.MinimumQuantity, this.getMeasurementUnitById(menuItem.UnitId))
          }),
          type: 'warning',
          icon: ''
        })
        return
      }

      const menuItemObj = JSON.parse(JSON.stringify(menuItem))
      const slug = (category && category.Lang[this.$i18n.locale] && category.Lang[this.$i18n.locale].Slug) || this.slug || undefined
      const menuItemSlug = greekUtils.slugify(`${ menuItemObj.Name }-${ menuItemObj.Id }`)

      if (this.UseMenuLandingPage) {
        this.$router.push({
          name  : 'Menu',
          params: {
            slug        : slug,
            menuItemSlug: menuItemSlug
          }
        })
      } else {
        this.$bus.$emit('show-menu-options-dialog', {
          menuItem: menuItemObj,
          editMode: false
        })
        this.$gtag && this.$gtag.pageview({
          page_title: menuItemObj.Lang && menuItemObj.Lang[this.$i18n.locale].Name,
          page_path : `/${ this.$i18n.locale }/catalog/${ slug }/${ menuItemSlug }`
        })
      }
    },

    getItemById (menuItemId, categorySlug) {
      let item = null
      if (menuItemId && categorySlug) {
        const category = Object.entries(this.dataStoreMenu.items).find((obj) => obj[1].Category.Lang[this.$i18n.locale] && obj[1].Category.Lang[this.$i18n.locale].Slug.toString() === categorySlug.toString())[1]
        if (category) item = category.Items.find(menu => menu.Id.toString() === menuItemId.toString())
      }
      return item
    },

    getCategoryBySlug (slug) {
      let category = null
      if (slug) category = this.dataStoreMenu?.categories.find(category => category.Lang[this.$i18n.locale] && category.Lang[this.$i18n.locale].Slug.toString() === slug.toString())
      return category
    },

    getCategoryImage (category) {
      return category?.Image ? `${ this.appConfig.LOCATION_DATA.CdnImagesUrl }${ category.Image }` : null
    },

    onSearchProduct (data) {
      this.searchProduct = data
      clearTimeout(this.debounceSearchTimeout)
      this.debounceSearchTimeout = setTimeout(() => {
        this.onSearchProductDebounced(data)
        window.scrollTo(0, 0)
      }, 500)
    },

    onSearchProductDebounced (data) {
      this.posMenuItemsActive = JSON.parse(JSON.stringify(this.dataStoreMenu.items))

      if (!data) {
        if (this.activeCategoryId && parseInt(this.activeCategoryId) !== -1) {
          if (!this.allCategories) {
            const categoryData = Object.entries(this.dataStoreMenu.items).find((obj) => obj[1].Category.Id.toString() === this.activeCategoryId.toString())[1]
            this.posMenuItemsActive = [categoryData ? JSON.parse(JSON.stringify(categoryData)) : []]
          }
        }
        return
      }

      data = data.trim().toLowerCase()

      let searchString = ''
      const useGreeklish = true

      if (useGreeklish) {
        searchString = greekUtils.toGreeklish(data)
      } else {
        searchString = greekUtils.sanitizeDiacritics(data)
      }

      if (data.length <= 0) return

      DataEventService.Emit(DataEventEnum.EVENT, {
        Event  : DataEventEnum.SEARCH,
        Payload: searchString
      })

      Object.keys(this.posMenuItemsActive).forEach(key => {
        if (useGreeklish) {
          this.posMenuItemsActive[key].Items = this.posMenuItemsActive[key].Items.filter(e => e.Status && e.Active && greekUtils.toGreeklish(e.Name.trim().toLowerCase()).includes(searchString) || greekUtils.toGreeklish(e.Description.trim().toLowerCase()).includes(searchString) || greekUtils.toGreeklish(JSON.stringify(this.getMenuItemTags(e)).trim().toLowerCase()).includes(searchString) || e.Number.includes(searchString))
        } else {
          this.posMenuItemsActive[key].Items = this.posMenuItemsActive[key].Items.filter(e => e.Status && e.Active && greekUtils.sanitizeDiacritics(e.Name.trim().toLowerCase()).includes(searchString) || greekUtils.sanitizeDiacritics(e.Description.trim().toLowerCase()).includes(searchString) || greekUtils.sanitizeDiacritics(JSON.stringify(this.getMenuItemTags(e)).trim().toLowerCase()).includes(searchString) || e.Number.includes(searchString))
        }
      })
    },

    getCategoryDetails (category, force) {
      if (this.$route.params.searchProduct) this.onSearchProductDebounced(this.$route.params.searchProduct)

      const id = category ? category.Id : category
      this.searchProduct = this.$route.params.searchProduct || ''
      if (parseInt(this.activeCategoryId) === parseInt(id) && !force) return
      this.activeCategoryId = id

      if (!this.allCategories) {
        if (id) {
          const categoryData = this.dataStoreMenu.items.find(obj => obj.Category && obj.Category.Id.toString() === id.toString())
          this.posMenuItemsActive = categoryData ? [categoryData] : []
        } else {
          this.posMenuItemsActive = (this.dataStoreMenu && this.dataStoreMenu.items) ? this.dataStoreMenu.items : []
        }

        if (category && category.Lang[this.$i18n.locale]) {
          this.setMetaTitle(category.Lang[this.$i18n.locale].Slug)
          this.$nextTick(() => {
            window.scroll(0, 250)
            if (this.$route.params.menuId) {
              this.showAddMenuOptionsDialog(this.getItemById(this.$route.params.menuId, category.Lang[this.$i18n.locale].Slug), category)
            }
          })
        }
      } else {
        this.posMenuItemsActive = this.dataStoreMenu?.items || []
        if (category && category.Lang[this.$i18n.locale]) {
          const isItemRenderedInDom = !!document.getElementById(this.$route.params.hashId ? this.$route.params.hashId : category.Lang[this.$i18n.locale].Slug)

          if (isItemRenderedInDom) {
            if (!this.menuItemSlug) {
              this.$vuetify.goTo('#' + (this.$route.params.hashId ? this.$route.params.hashId : category.Lang[this.$i18n.locale].Slug).toString(), {
                duration: this.allCategories ? 500 : 0,
                easing  : 'easeInOutCubic',
                offset  : 10
              })
            }
            if (this.$route.params.menuId) {
              this.showAddMenuOptionsDialog(this.getItemById(this.$route.params.menuId, category.Lang[this.$i18n.locale].Slug), category)
            }
          } else {
            this.$nextTick(() => {
              this.$vuetify.goTo('#' + (this.$route.params.hashId ? this.$route.params.hashId : category.Lang[this.$i18n.locale].Slug).toString(), {
                duration: this.allCategories ? 500 : 0,
                easing  : 'easeInOutCubic',
                offset  : 10
              })

              if (this.$route.params.menuId) {
                this.showAddMenuOptionsDialog(this.getItemById(this.$route.params.menuId, category.Lang[this.$i18n.locale].Slug), category)
              }
            })
          }
        }
      }
    },

    setMetaTitle (slug) {
      let title = this.$t('Menu.Title')
      let desc = ''
      let img = ''
      let category = null
      if (slug && this.dataStoreMenu) {
        category = this.dataStoreMenu.categories.find(category => category.Lang[this.$i18n.locale] && category.Lang[this.$i18n.locale].Slug === slug)
      }
      title = category && category.Lang[this.$i18n.locale] ? title + ' - ' + category.Lang[this.$i18n.locale].Name : title
      desc = category && category.Lang[this.$i18n.locale] && category.Lang[this.$i18n.locale].Description ? category.Lang[this.$i18n.locale].Name + ' - ' + category.Lang[this.$i18n.locale].Description : ''
      img = category && category.Image ? window.AppConfig.LOCATION_DATA.CdnImagesUrl + category.Image : ''
      if (title !== this.title) this.title = title
      if (desc !== this.desc) this.desc = desc
      if (img !== this.img) this.img = img
    }
  }
}
